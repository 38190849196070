import Vue from 'vue'
import {createInertiaApp} from '@inertiajs/inertia-vue'
import {InertiaProgress} from '@inertiajs/progress'

InertiaProgress.init()

import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)

import './components'
import './plugins'

createInertiaApp({
    resolve: name => import(`./Pages/${name}`),
    setup({el, app, props}) {
        new Vue({
            render: h => h(app, props),
        }).$mount(el)
    },
})
