import Vue from 'vue'

import './axios'
import './lang'

import VueMeta from 'vue-meta'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.use(VueLodash, {lodash: lodash})

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

import PortalVue from 'portal-vue'

Vue.use(PortalVue)

import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.mixin({
    methods: {
        route: (name, params, absolute) => route(`admin-panel.${name}`, params, absolute),
        isRoute: (name) => route(null, {}, null, Ziggy).current('admin-panel.' + name),
        isRouteCurrent: (name) => route(null, {}, null, Ziggy).current('admin-panel.' + name + '.*'),
        routeIncludes: (fragments) => route(null, {}, null, Ziggy)
            .current()
            .match(new RegExp(`(${fragments.join('|')})`)),

        can(permission) {
            return !!this.$page.props.auth.permissions.includes(permission)
        }
    },
})
